import React, { useContext } from 'react';
import styled from 'styled-components';

import { GlobalContext } from './GlobalContext';

const PageBackgroundColor = () => {
  const { pageBgColor } = useContext(GlobalContext);

  return <Wrapper className="full-width-container" pageBgColor={pageBgColor} />;
};

export default PageBackgroundColor;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  /* Overriding the 'full-width-container' class positioning */
  position: absolute !important;
  top: 0;
  bottom: 0;
  background-color: ${props => props.pageBgColor};
  z-index: -1;
  will-change: background-color;
  transform: translate3d(0, 0, 0);
  transition: background-color 400ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
`;
