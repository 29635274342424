import React, { createContext, useReducer } from 'react';

const initialState = {
  currentSpotifyEpisode: null,
  pageBgColor: 'var(--color-snow)',
  storyColor: 'var(--color-snow)',
  storyHeaderColor: 'var(--color-snow)',
  spotifyAccessToken: null,
  storiesPageLayout: 'grid',
  currentTemp: null,
  currentDate: null,
};

const actions = {
  SET_CURRENT_SPOTIFY_EPISODE: 'SET_CURRENT_SPOTIFY_EPISODE',
  SET_PAGE_BG_COLOR: 'SET_PAGE_BG_COLOR',
  SET_STORY_COLOR: 'SET_STORY_COLOR',
  SET_STORY_HEADER_COLOR: 'SET_STORY_HEADER_COLOR',
  SET_SPOTIFY_ACCESS_TOKEN: 'SET_SPOTIFY_ACCESS_TOKEN',
  SET_STORIES_PAGE_LAYOUT: 'SET_STORIES_PAGE_LAYOUT',
  SET_CURRENT_TEMP: 'SET_CURRENT_TEMP',
  SET_CURRENT_DATE: 'SET_CURRENT_DATE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_SPOTIFY_EPISODE:
      return { ...state, currentSpotifyEpisode: action.value };
    case actions.SET_PAGE_BG_COLOR:
      return { ...state, pageBgColor: action.value };
    case actions.SET_STORY_COLOR:
      return { ...state, storyColor: action.value };
    case actions.SET_STORY_HEADER_COLOR:
      return { ...state, storyHeaderColor: action.value };
    case actions.SET_SPOTIFY_ACCESS_TOKEN:
      return { ...state, spotifyAccessToken: action.value };
    case actions.SET_STORIES_PAGE_LAYOUT:
      return { ...state, storiesPageLayout: action.value };
    case actions.SET_CURRENT_TEMP:
      return { ...state, currentTemp: action.value };
    case actions.SET_CURRENT_DATE:
      return { ...state, currentDate: action.value };
    default:
      return state;
  }
};

const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    currentSpotifyEpisode: state.currentSpotifyEpisode,
    setCurrentSpotifyEpisode: value => {
      dispatch({ type: actions.SET_CURRENT_SPOTIFY_EPISODE, value });
    },
    pageBgColor: state.pageBgColor,
    setPageBgColor: value => {
      dispatch({ type: actions.SET_PAGE_BG_COLOR, value });
    },
    storyColor: state.storyColor,
    setStoryColor: value => {
      dispatch({ type: actions.SET_STORY_COLOR, value });
    },
    storyHeaderColor: state.storyHeaderColor,
    setStoryHeaderColor: value => {
      dispatch({ type: actions.SET_STORY_HEADER_COLOR, value });
    },
    spotifyAccessToken: state.spotifyAccessToken,
    setSpotifyAccessToken: value => {
      dispatch({ type: actions.SET_SPOTIFY_ACCESS_TOKEN, value });
    },
    storiesPageLayout: state.storiesPageLayout,
    setStoriesPageLayout: value => {
      dispatch({ type: actions.SET_STORIES_PAGE_LAYOUT, value });
    },
    currentTemp: state.currentTemp,
    setCurrentTemp: value => {
      dispatch({ type: actions.SET_CURRENT_TEMP, value });
    },
    currentDate: state.currentDate,
    setCurrentDate: value => {
      dispatch({ type: actions.SET_CURRENT_DATE, value });
    },
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export { GlobalContextProvider as default, GlobalContext };
