import React from 'react';
import { useStaticQuery, graphql, Script } from 'gatsby';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import { toKebabCase } from '../assets/js/utilities';

import bp from '../assets/js/breakpoints';
import SocialIcon from './Icons/SocialIcon';

const NewsletterSignup = () => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicGlobalLayout {
        _previewable
        data {
          newsletter_title
          newsletter_description {
            richText
          }
          newsletter_email_placeholder
          newsletter_phone_number_placeholder
        }
        ...SocialItems
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicGlobalLayout;
  const {
    newsletter_title,
    newsletter_description,
    newsletter_email_placeholder,
    newsletter_phone_number_placeholder,
    social_menu,
  } = document.data;

  return (
    <NewsletterWrapper>
      <>
        <h2 className="title__mobile">
          <svg viewBox="0 0 59.5 11.2">
            <text x="0" y="10.9">
              Subscribe
            </text>
          </svg>
        </h2>
        <h2 className="title__desktop">{newsletter_title}</h2>

        <PrismicRichText field={newsletter_description.richText} />

        <div id="mc_embed_shell">
          <div id="mc_embed_signup">
            <form
              action="https://heybaycity.us13.list-manage.com/subscribe/post?u=9549e4a68a743932b283726b7&amp;id=58a34e5511&amp;f_id=000090e2f0"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              target="_blank"
            >
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                defaultValue=""
                placeholder={newsletter_email_placeholder}
                required
              />

              <div>
                <input
                  type="text"
                  name="SMSPHONE"
                  id="mce-SMSPHONE"
                  defaultValue=""
                  placeholder={newsletter_phone_number_placeholder}
                />

                <OptIn>
                  <div className="opt-in__terms">
                    By providing your phone number, you agree to receive
                    promotional and marketing messages, notifications, and
                    customer service communications from Hey, Bay City!. Message
                    and data rates may apply. Consent is not a condition of
                    purchase. Message frequency varies. Text HELP for help. Text
                    STOP to cancel.
                    <a
                      href="http://eepurl.com/iAh4Pc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      See terms
                    </a>
                  </div>
                </OptIn>
              </div>

              <div id="mce-responses">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                ></div>

                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                ></div>
              </div>

              <div
                aria-hidden="true"
                style={{ position: 'absolute', left: '-5000px' }}
              >
                <input
                  type="text"
                  name="b_9549e4a68a743932b283726b7_58a34e5511"
                  tabIndex="-1"
                  defaultValue=""
                />
              </div>

              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                value="Sign Up"
              />
            </form>
          </div>

          <Script
            type="text/javascript"
            src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
          />
        </div>

        <SocialIcons>
          {social_menu.map(item => (
            <li key={item.link_text}>
              <a
                href={item.link_url.url}
                target="_blank"
                rel="noreferrer"
                aria-label={item.link_text}
              >
                <SocialIcon platform={toKebabCase(item.link_text)} />
              </a>
            </li>
          ))}
        </SocialIcons>
      </>
    </NewsletterWrapper>
  );
};

export default NewsletterSignup;

/**
 * Styled Components
 *
 */
const NewsletterWrapper = styled.section`
  position: relative;
  background-color: var(--color-snow);
  text-align: center;
  padding: var(--space-m) var(--page-padding);

  @media (${bp.min.xs_sm}) {
    padding: var(--space-xxl) 0;
  }

  @media (${bp.min.sm}) {
    z-index: 10;
  }

  .title__desktop {
    font-size: 4.2rem;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin-bottom: var(--space-s);
    display: none;

    @media (${bp.min.xs_sm}) {
      display: block;
    }
  }

  .title__mobile {
    margin-bottom: var(--space-s);
    border-top: 0.5rem solid var(--color-navy);
    padding-top: var(--space-s);

    @media (${bp.min.xs_sm}) {
      display: none;
    }

    svg {
      width: 100%;
      font-family: var(--font-family-national-condensed);
      font-weight: var(--font-weight-extrabold);
      font-size: 1.6rem;
      line-height: 1;
      text-transform: uppercase;
      letter-spacing: -0.02rem;
      fill: var(--color-navy);
    }
  }

  p {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 12rem;

    @media (${bp.min.xs}) {
      margin: 0 auto var(--space-xl);
      width: 100%;
      max-width: 36rem;
    }
  }

  form,
  .response {
    position: relative;
    width: 100%;
    max-width: 56rem;
    margin: 0 auto;
  }

  .response {
    margin: var(--space-xs) 0 var(--space-m);
    font-size: 1.4rem;
    font-weight: var(--font-weight-bold);

    &#mce-error-response {
      color: #d03c25;
    }

    a {
      font-weight: var(--font-weight-bold);

      &:hover {
        color: var(--color-great-blue);
      }
    }
  }

  input:not([type='checkbox'], [type='submit']) {
    width: 100%;
    padding: var(--space-xxs) 0;
    background-color: transparent;
    border: none;
    border-bottom: 0.1rem solid var(--color-navy);
    border-radius: 0;
    font-size: 2.8rem;
    color: var(--color-navy);
    font-weight: var(--font-weight-bold);
    text-align: center;
    margin-bottom: var(--space-l);
    transition: border-color 200ms var(--ease-cubic-in-out);

    @media (${bp.min.xs_sm}) {
      margin-bottom: var(--space-m);
    }

    &:focus {
      outline: 0;
      border-color: var(--color-navy);

      &::placeholder {
        opacity: 0.15;
      }
    }

    &::placeholder {
      color: var(--color-black);
      opacity: 0.4;
      transition: opacity 200ms var(--ease-cubic-in-out);
    }
  }

  input[type='submit'] {
    font-size: 1.5rem;
    line-height: 1;
    background-color: var(--color-great-blue);
    color: var(--color-snow);
    border-radius: 10rem;
    position: relative;
    transition: all 250ms var(--ease-cubic-in-out);
    padding: var(--space-s) 4.8rem;
    border: none;
    cursor: pointer;

    &:hover:not(:disabled) {
      cursor: pointer;
      background-color: var(--color-navy);
    }

    &:disabled {
      opacity: 0.5;

      @media (${bp.min.xs_sm}) {
        opacity: 0.5;
      }
    }
  }
`;

const OptIn = styled.div`
  display: flex;
  gap: var(--space-xxs);
  align-items: flex-start;
  margin-bottom: var(--space-m);

  input {
    margin: 0.1rem 0 0 0;
  }

  .opt-in__terms {
    font-size: 1.2rem;
    line-height: 1.3;
    text-align: center;

    @media (${bp.min.xs}) {
      margin: 0 auto var(--space-xl);
      width: 100%;
      max-width: 40rem;
    }

    a {
      font-weight: var(--font-weight-bold);
      margin-left: var(--space-xxxs);
    }
  }
`;

const SocialIcons = styled.ul`
  display: flex;
  align-items: center;
  margin-top: 12rem;
  justify-content: space-between;

  @media (${bp.min.xs}) {
    margin-top: var(--space-xxl);
    justify-content: center;
  }

  li {
    @media (${bp.min.xs}) {
      padding: 0 var(--space-s);
    }

    a {
      display: flex;

      &:hover {
        svg {
          fill: var(--color-great-blue);
        }
      }

      svg {
        transition: all 250ms var(--ease-cubic-in-out);
      }
    }
  }
`;
