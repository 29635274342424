import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { useClickAway, useCopyToClipboard, useToggle } from 'react-use';

import bp from '../assets/js/breakpoints';
import { isBrowser } from '../assets/js/utilities';

import SocialIcon from '../components/Icons/SocialIcon';
import LinkIcon from './Icons/LinkIcon';
import ShareIcon from './Icons/ShareIcon';

const ShareMobile = ({
  url,
  title,
  contrastingColor,
  reverseContrastingColor,
  variant,
}) => {
  // Get page origin url
  const origin = isBrowser ? window.location.origin : '';

  const [state, copyToClipboard] = useCopyToClipboard();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDropdown, setShowDropdown] = useToggle(false);

  const shareWrapper = useRef(null);
  useClickAway(shareWrapper, () => {
    setShowDropdown(false);
  });

  useEffect(() => {
    if (state.value) {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    }
  }, [state]);

  return (
    <Wrapper ref={shareWrapper} variant={variant}>
      <ShareButton
        onClick={setShowDropdown}
        showDropdown={showDropdown}
        contrastingColor={contrastingColor}
        reverseContrastingColor={reverseContrastingColor}
        variant={variant}
      >
        <ShareIcon />
      </ShareButton>

      <Dropdown showDropdown={showDropdown} variant={variant}>
        <h2>Share Story</h2>

        <ul>
          <li>
            <button onClick={() => copyToClipboard(`${origin}${url}`)}>
              <LinkIcon />
              <span>
                {state.error ? (
                  <p className="error">Error</p>
                ) : showSuccess ? (
                  <p>Copied</p>
                ) : (
                  'Copy Link'
                )}
              </span>
            </button>
          </li>

          <li>
            <TwitterShareButton title={title} url={`${origin}${url}`}>
              <SocialIcon platform="twitter" />
              <span>Twitter</span>
            </TwitterShareButton>
          </li>

          <li>
            <FacebookShareButton url={`${origin}${url}`}>
              <SocialIcon platform="facebook" />
              <span>Facebook</span>
            </FacebookShareButton>
          </li>
        </ul>
      </Dropdown>
    </Wrapper>
  );
};

export default ShareMobile;

/**
 * Styled Components
 *
 */

const Wrapper = styled.div`
  position: relative;

  ${props =>
    props.variant === 'basic-page' &&
    `
    grid-column: content;
    margin-top: var(--space-xxl);
    width: fit-content;
  `};

  @media (${bp.min.sm}) {
    display: none;
  }
`;

const ShareButton = styled.button`
  --size: 4.8rem;

  position: relative;
  width: var(--size);
  height: var(--size);
  background-color: ${props =>
    props.variant === 'basic-page'
      ? 'var(--color-navy)'
      : props.showDropdown
      ? props.reverseContrastingColor
      : props.contrastingColor};
  border-radius: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.variant === 'basic-page' &&
    `
    &:after {
      content: 'Share Story';
      position: absolute;
      left: 6rem;
      top: 50%;
      transform: translateY(-50%);
      white-space: nowrap;
      font-size: 1.2rem;
      line-height: 1;
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.04rem;
      text-transform: uppercase;
      color: var(--color-navy);
    }
  `}

  svg {
    fill: ${props =>
      props.showDropdown
        ? props.contrastingColor
        : props.reverseContrastingColor};
  }
`;

const Dropdown = styled.div`
  position: absolute;
  bottom: -17rem;
  z-index: 1;
  width: 18rem;
  padding: var(--space-s);
  background-color: var(--color-white);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  opacity: ${props => (props.showDropdown ? 1 : 0)};
  pointer-events: ${props => (props.showDropdown ? 'auto' : 'none')};
  transform: translateY(${props => (props.showDropdown ? 0 : '-0.4rem')});
  transition: all 150ms var(--ease-cubic-in-out);

  ${props => props.variant !== 'basic-page' && `right: 0;`};

  h2 {
    font-size: 1.5rem;
    line-height: 1;
    font-weight: var(--font-weight-regular);
  }

  ul {
    margin-top: var(--space-s);
  }

  li + li {
    margin-top: var(--space-xs);
  }

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 2.4rem;
    color: var(--color-navy);

    span {
      font-size: 1.2rem;
      line-height: 1;
      font-weight: var(--font-weight-bold);
      letter-spacing: 0.04rem;
      text-transform: uppercase;
      margin-left: var(--space-xs);
    }
  }

  svg {
    width: 1.8rem;
    height: auto;
  }

  .error {
    color: #b71c1c;
  }
`;
