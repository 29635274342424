exports.linkResolver = doc => {
  // URL for a story type
  if (doc.type === 'story') {
    return `/stories/${doc.uid}/`;
  }

  // URL for top-level page types
  if (
    doc.type === 'basic_page' ||
    doc.type === 'stories' ||
    doc.type === 'jobs'
  ) {
    return `/${doc.uid}/`;
  }

  // Backup for all other types
  return '/';
};
