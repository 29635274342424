import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import bp from '../../assets/js/breakpoints';

import Link from '../Link';
import Logo from '../Logo';

const FooterMobile = () => {
  const staticData = useStaticQuery(graphql`
    {
      prismicGlobalLayout {
        _previewable
        data {
          partners_title
          partners {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicGlobalLayout;
  const { partners_title, partners } = document.data;

  return (
    <>
      <FooterWrapper>
        <Inner>
          <Link url={{ url: '/', link_type: 'Document' }}>
            <Logo fill="var(--color-navy)" />
          </Link>

          <p className="copyright">
            &copy; {new Date().getFullYear()} Hey Bay City
          </p>
        </Inner>
      </FooterWrapper>

      <Partnerships>
        <p>{partners_title}</p>

        <Partners>
          {partners.map(item => (
            <li key={item.link_text}>
              <Link url={item.link_url}>{item.link_text}</Link>
            </li>
          ))}
        </Partners>
      </Partnerships>
    </>
  );
};

export default FooterMobile;

/**
 * Styled Components
 *
 */
const FooterWrapper = styled.footer`
  background-color: var(--color-snow);
  color: var(--color-navy);
  border-top: 0.1rem solid var(--color-navy);
  margin: 0 var(--page-padding);
  text-align: center;
  position: relative;
  z-index: 1;

  @media (${bp.min.sm_md}) {
    display: none;
  }

  .logo {
    width: 100%;
    height: auto;

    &:hover {
      fill: var(--color-great-blue);
    }
  }

  .copyright {
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    font-weight: var(--font-weight-bold);
  }
`;

const Inner = styled.div`
  position: relative;
  height: 100%;
  background-color: var(--color-snow);
  padding: var(--space-l) 0;

  &:after {
    content: '';
    width: 100vw;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
    background-color: var(--color-snow);
  }
`;

const Partnerships = styled.div`
  background-color: var(--color-navy);
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  padding: var(--space-l) var(--page-padding) var(--space-m);
  text-align: left;
  width: 100vw;
  bottom: 0;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: -2;

  @media (${bp.min.sm_md}) {
    display: none;
  }

  p {
    color: var(--color-baybee-blue);
  }
`;

const Partners = styled.ul`
  list-style-type: none;
  column-count: 2;
  column-gap: var(--space-xl);
  margin-top: var(--space-m);

  li + li {
    margin-top: var(--space-s);
  }

  a {
    color: var(--color-snow);
  }
`;
