import { useEffect, useContext } from 'react';
import axios from 'axios';
import { useLocalStorage } from 'react-use';

import { GlobalContext } from './GlobalContext';

const SpotifyAccessToken = () => {
  const { setSpotifyAccessToken } = useContext(GlobalContext);
  const [accessToken, setAccessToken] = useLocalStorage(
    'spotifyAccessToken',
    null
  );
  const [expiresIn, setExpiresIn] = useLocalStorage('spotifyExpiresIn', null);

  useEffect(() => {
    axios
      .get('/.netlify/functions/getSpotifyAccessToken')
      .then(res => {
        // If local storage value exists and isn't expired, set to global state
        // Otherwise, set a new value and reset the expire timer
        if (!accessToken || new Date().getTime() > expiresIn) {
          setAccessToken(res.data);
          setExpiresIn(new Date().getTime() + 3600);
          setSpotifyAccessToken(res.data);
        } else {
          setSpotifyAccessToken(accessToken);
        }
      })
      .catch(err => {
        console.error(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, expiresIn]);

  return null;
};

export default SpotifyAccessToken;
