import React, { useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { InertiaPlugin } from 'gsap/InertiaPlugin';
import { useIsomorphicLayoutEffect, useKey } from 'react-use';

import bp from '../../assets/js/breakpoints';
import { horizontalLoop, isBrowser } from '../../assets/js/utilities';

import StoryCardFullImage from '../StoryCardFullImage';
import ArrowIcon from '../Icons/ArrowIcon';

if (isBrowser) {
  gsap.registerPlugin(Draggable, InertiaPlugin);
}

const RecentStoriesSliderDesktop = ({ stories }) => {
  const slider = useRef(null);
  const loop = useRef(null);

  useIsomorphicLayoutEffect(() => {
    const query = gsap.utils.selector(slider);

    const cards = query('.slider-slide');

    loop.current = horizontalLoop(gsap, Draggable, InertiaPlugin, cards, {
      paused: false,
      speed: 0.5,
      paddingRight: '20px',
      draggable: true,
      center: true,
      repeat: -1,
    });

    return () => {
      loop.current.kill();
    };
  }, []);

  function handleSlideshowPause() {
    if (!loop.current) return;

    loop.current.pauseTL();
  }

  function handleSlideshowPlay() {
    if (!loop.current) return;

    loop.current.playTL();
  }

  function handleSlideNavigation(direction) {
    if (!loop.current) return;

    const options = { duration: 0.4, ease: 'power1.inOut' };

    if (direction === 'previous') {
      loop.current.previous(options);
    } else if (direction === 'next') {
      loop.current.next(options);
    }
  }

  useKey('ArrowLeft', () => handleSlideNavigation('previous'));
  useKey('ArrowRight', () => handleSlideNavigation('next'));

  return (
    <Wrapper className="full-width-container">
      <Slider>
        <Navigation>
          <button
            onClick={() => handleSlideNavigation('previous')}
            className="button button--previous"
            aria-label="previous"
          >
            <ArrowIcon direction="left" />
          </button>

          <button
            onClick={() => handleSlideNavigation('next')}
            className="button button--next"
            aria-label="next"
          >
            <ArrowIcon direction="right" />
          </button>
        </Navigation>

        <SliderInner
          ref={slider}
          onMouseEnter={handleSlideshowPause}
          onMouseLeave={handleSlideshowPlay}
        >
          {stories.map((story, i) => (
            <Slide key={i} className="slider-slide">
              <StoryCardFullImage story={story} />
            </Slide>
          ))}
        </SliderInner>
      </Slider>
    </Wrapper>
  );
};

export default RecentStoriesSliderDesktop;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  margin-top: 6rem;
  display: none;

  @media (${bp.min.sm}) {
    display: block;
  }
`;

const Slider = styled.div`
  overflow: hidden;
  position: relative;
`;

const SliderInner = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const Slide = styled.div`
  flex-shrink: 0;
  margin-right: 2rem;
  width: 40vw;

  @media (${bp.min.md}) {
    width: 30vw;
  }

  @media (${bp.min.lg}) {
    width: 28vw;
  }

  @media (${bp.min.xl}) {
    width: 24vw;
  }
`;

const Navigation = styled.div`
  --size: 5.6rem;

  position: absolute;
  left: var(--page-padding);
  right: var(--page-padding);
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  height: var(--size);
  pointer-events: none;

  .button {
    position: absolute;
    cursor: pointer;
    width: var(--size);
    height: var(--size);
    transition: all 250ms var(--ease-cubic-in-out);
    border: 0.1rem solid var(--color-snow);
    border-radius: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: auto;

    svg {
      transition: all 250ms var(--ease-cubic-in-out);
    }

    @media (hover: hover) {
      &:hover {
        background-color: var(--color-snow);

        svg {
          stroke: var(--color-navy);
        }
      }
    }
  }

  .button--previous {
    left: 0;
  }

  .button--next {
    right: 0;
  }
`;
