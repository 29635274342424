import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions';

import { GlobalStyle } from './Styles.globals';
import GlobalContextProvider from './GlobalContext';

import { isBrowser } from '../assets/js/utilities';

import Header from './Header';
import NewsletterSignup from './NewsletterSignup';
import Footer from './Footer';
import PageBackgroundColor from './PageBackgroundColor';
import SpotifyAccessToken from './SpotifyAccessToken';
import SpotifyPlayer from './SpotifyPlayer';

import '../assets/css/fonts.css';

const Layout = ({ location, children }) => {
  // Determine if current page is the homepage
  const path = isBrowser ? window.location.pathname : '';
  const isHomepage = path === '/';

  // Fix React Hydration errors
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <TransitionProvider
      location={location}
      enter={{
        opacity: 0,
        config: {
          clamp: true,
        },
      }}
      usual={{
        opacity: 1,
      }}
      leave={{
        opacity: 0,
        config: {
          duration: 400,
        },
      }}
    >
      <GlobalContextProvider>
        <GlobalStyle />
        <SpotifyAccessToken />

        <a href="#main" className="skip-to-main">
          Skip to main content
        </a>

        <Wrapper>
          <Header isHomepage={isHomepage} />

          <main id="main">
            <ParallaxProvider>
              <TransitionViews>{children}</TransitionViews>
            </ParallaxProvider>
          </main>

          <PageBackgroundColor />
        </Wrapper>

        <NewsletterSignup />
        <Footer />

        <SpotifyPlayer />
      </GlobalContextProvider>
    </TransitionProvider>
  );
};

export default Layout;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: var(--width-max);
  padding: 0 var(--page-padding);
  margin: 0 auto;
  z-index: 10;
  background-color: var(--color-snow);
`;
