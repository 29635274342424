import React from 'react';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import bp from '../../assets/js/breakpoints';
import { linkResolver } from '../../utilities/link-resolver';

const Text = ({ slice }) => {
  const data = slice.primary;

  return (
    <Wrapper className="text-slice">
      <PrismicRichText field={data.text.richText} linkResolver={linkResolver} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  grid-column: content;

  h2 {
    font-size: clamp(2.8rem, 3.1471rem + 1.1029vw, 6rem);
    line-height: 1;
    letter-spacing: -0.15rem;
    margin-bottom: var(--space-xs);
  }

  h3 {
    font-size: clamp(2.4rem, 1.6471rem + 1.1029vw, 3.5rem);
    line-height: 1.2;
    letter-spacing: -0.05rem;
    margin-bottom: var(--space-xxxs);
    font-weight: var(--font-weight-bold);
  }

  h4 {
    font-size: clamp(1.8rem, 1.75rem + 1vw, 2.8rem);
    line-height: 1.4;
    font-weight: var(--font-weight-bold);
    margin-bottom: var(--space-xxxs);
  }

  a {
    color: var(--color-great-blue);

    &:hover {
      color: var(--color-navy);
    }
  }

  p {
    font-size: 2rem;
    line-height: 1.4;
    letter-spacing: -0.03em;

    @media (${bp.min.sm}) {
      font-size: 2.2rem;
    }

    & + h2,
    & + h3,
    & + h4 {
      margin-top: 4.8rem;

      @media (${bp.min.xs_sm}) {
        margin-top: 6rem;
      }
    }

    & + p {
      margin-top: var(--space-m);
    }
  }

  ul,
  ol {
    margin-top: var(--space-xxs);
    font-size: 2rem;

    li {
      margin-top: var(--space-xxxs);
    }

    & + h2,
    & + h3,
    & + h4 {
      margin-top: var(--space-xl);

      @media (${bp.min.sm}) {
        margin-top: var(--space-xxl);
      }
    }

    & + p {
      margin-top: var(--space-m);
    }
  }

  ul {
    margin-bottom: var(--space-l);
    margin-left: 1em;
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  .block-img {
    & + p {
      margin-top: var(--space-xs);
    }
  }

  img {
    max-width: 100%;
  }
`;

export default Text;
