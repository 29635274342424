import { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { GlobalContext } from '../components/GlobalContext';

import { convertMsToHMS } from '../assets/js/utilities';

function useTrackLength(spotify_id) {
  const { spotifyAccessToken } = useContext(GlobalContext);

  const [trackLength, setTrackLength] = useState(null);

  // Get Track length from Spotify API
  useEffect(() => {
    if (!spotifyAccessToken || !spotify_id) return;

    const config = {
      headers: {
        Authorization: `Bearer ${spotifyAccessToken}`,
        'Content-Type': 'application/json',
      },
    };

    axios
      .get(
        `https://api.spotify.com/v1/episodes/${spotify_id}?market=US`,
        config
      )
      .then(res => {
        const { duration_ms } = res.data;

        const convertedTime = convertMsToHMS(duration_ms);

        setTrackLength(convertedTime);
      })
      .catch(err => {
        console.error(err);
      });
  }, [spotify_id, spotifyAccessToken]);

  return trackLength;
}

export default useTrackLength;
