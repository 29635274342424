import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { useCopyToClipboard } from 'react-use';

import bp from '../assets/js/breakpoints';
import { isBrowser } from '../assets/js/utilities';

import SocialIcon from '../components/Icons/SocialIcon';
import LinkIcon from './Icons/LinkIcon';

const ShareDesktop = ({ url, title }) => {
  // Get page origin url
  const origin = isBrowser ? window.location.origin : '';

  const [state, copyToClipboard] = useCopyToClipboard();
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (state.value) {
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    }
  }, [state]);

  return (
    <Wrapper>
      <h2>Share Story</h2>

      <ul>
        <li>
          <Tooltip showTooltip={showSuccess || state.error}>
            {state.error ? (
              <p className="error">Unable to copy value</p>
            ) : (
              showSuccess && <p>Copied to clipboard</p>
            )}
          </Tooltip>

          <button
            onClick={() => copyToClipboard(`${origin}${url}`)}
            aria-label="Copy to clipboard"
          >
            <LinkIcon />
          </button>
        </li>

        <li>
          <TwitterShareButton title={title} url={`${origin}${url}`}>
            <SocialIcon platform="twitter" />
          </TwitterShareButton>
        </li>

        <li>
          <FacebookShareButton url={`${origin}${url}`}>
            <SocialIcon platform="facebook" />
          </FacebookShareButton>
        </li>
      </ul>
    </Wrapper>
  );
};

export default ShareDesktop;

/**
 * Styled Components
 *
 */

const Tooltip = styled.div`
  position: absolute;
  bottom: -4rem;
  left: 50%;
  color: var(--color-navy);
  padding: var(--space-xxs);
  font-size: 1.4rem;
  line-height: 1;
  white-space: nowrap;
  opacity: ${props => (props.showTooltip ? 1 : 0)};
  transform: translate(-50%, ${props => (props.showTooltip ? 0 : '-0.4rem')});
  transition: all 200ms var(--ease-cubic-in-out);

  .error {
    color: #b71c1c;
  }
`;

const Wrapper = styled.div`
  grid-column: content;
  margin-top: var(--space-xxl);
  display: none;

  @media (${bp.min.sm}) {
    display: block;
  }

  h2 {
    font-size: 1.2rem;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    text-transform: uppercase;
  }

  ul {
    display: flex;
    align-items: center;
    margin-top: var(--space-s);
  }

  li {
    position: relative;

    & + li {
      margin-left: var(--space-s);
    }
  }

  button {
    cursor: pointer;
    position: relative;
    display: flex;

    &:after {
      content: '';
      position: absolute;
      top: -0.4rem;
      right: -0.4rem;
      bottom: -0.4rem;
      left: -0.4rem;
    }

    &:hover {
      svg {
        fill: var(--color-great-blue);
      }
    }
  }

  svg {
    width: 1.8rem;
    height: auto;
    transition: fill 150ms var(--ease-cubic-in-out);
  }
`;
