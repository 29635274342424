import React from 'react';
import styled from 'styled-components';
import { PrismicRichText } from '@prismicio/react';

import bp from '../../assets/js/breakpoints';

const Quote = ({ slice }) => {
  const data = slice.primary;

  return (
    <Wrapper>
      <blockquote>
        <PrismicRichText field={data.quote.richText} />
      </blockquote>

      {data.attribution && <figcaption>{data.attribution}</figcaption>}
    </Wrapper>
  );
};

export default Quote;

/**
 * Styled Components
 *
 */
const Wrapper = styled.figure`
  grid-column: popout;
  padding: var(--space-m) 0 var(--space-l);
  margin: 5.6rem 0;
  border-top: 0.1rem solid var(--color-navy);
  border-bottom: 0.1rem solid var(--color-navy);

  @media (${bp.min.sm}) {
    padding: var(--space-l) 5vw var(--space-xl) 0;
    margin: var(--space-xxl) 0;
  }

  p {
    font-size: clamp(2.8rem, 5vw, 4.2rem);
    line-height: 1.15;
    font-weight: var(--font-weight-bold);
    letter-spacing: -0.05em;
  }

  figcaption {
    margin-top: var(--space-s);
    font-size: 2.2rem;
    font-weight: var(--font-weight-bold);
  }
`;
