import React from 'react';
import styled from 'styled-components';

const ArrowIcon = ({
  stroke = 'var(--color-snow)',
  direction = 'right',
  size = 22,
}) => {
  return (
    <Wrapper
      width={size}
      height={size}
      viewBox="0 0 21 22"
      fill="none"
      stroke={stroke}
      strokeWidth={1}
      xmlns="http://www.w3.org/2000/svg"
      direction={direction}
      className="arrow-icon"
    >
      <title>Arrow</title>
      <path
        d="M11 0.927124L20 10.9091L11 20.8577M20 10.8924H0"
        strokeMiterlimit="10"
      />
    </Wrapper>
  );
};

export default ArrowIcon;

const Wrapper = styled.svg`
  transform: scale(${props => (props.direction === 'left' ? '-1' : null)});
`;
