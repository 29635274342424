import React, { useRef, useEffect, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import Headroom from 'headroom.js';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import bp from '../assets/js/breakpoints';

import Link from './Link';
import Logo from './Logo';
import Menu from './Menu';
import { getContrastingColor } from '../assets/js/utilities';

const HomepageHeader = ({ isHomepage, desktopMenuLink, desktopMenuButton }) => {
  return (
    <HomepageHeaderWrapper isHomepage={isHomepage}>
      <ul>
        {desktopMenuLink.map(item => (
          <li key={item.link_text}>
            <Link url={item.link_url}>{item.link_text}</Link>
          </li>
        ))}

        <li className="button">
          <Link url={desktopMenuButton[0].link_url}>
            {desktopMenuButton[0].link_text}
          </Link>
        </li>
      </ul>
    </HomepageHeaderWrapper>
  );
};

const SecondaryHeader = ({
  desktopMenuLink,
  desktopMenuButton,
  isHomepage,
  menuIsOpen,
  toggleMenuIsOpen,
}) => {
  const header = useRef(null);

  const { storyHeaderColor } = useContext(GlobalContext);

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(storyHeaderColor);

  useEffect(() => {
    const options = {
      offset: isHomepage ? 0 : 50,
      tolerance: {
        up: 20,
        down: 0,
      },
    };

    const headroom = new Headroom(header.current, options);
    headroom.init();

    return () => {
      // Fixing an issue with headroom. Hopefully can remove soon.
      //  Github issue: https://github.com/WickyNilliams/headroom.js/issues/367
      setTimeout(() => {
        headroom.destroy();
      }, 500);
    };
  }, [isHomepage]);

  return (
    <SecondaryHeaderWrapper
      className="secondary-header"
      isHomepage={isHomepage}
      ref={header}
    >
      <InnerWrapper
        isHomepage={isHomepage}
        menuIsOpen={menuIsOpen}
        contrastingColor={contrastingColor}
      >
        <Link
          url={{ url: '/', link_type: 'Document' }}
          onMenuClick={menuIsOpen ? toggleMenuIsOpen : null}
        >
          <Logo alignBaseline />
        </Link>

        <ul>
          {desktopMenuLink.map(item => (
            <li key={item.link_text}>
              <Link url={item.link_url}>{item.link_text}</Link>
            </li>
          ))}
        </ul>

        <div className="button">
          <Link url={desktopMenuButton[0].link_url}>
            {desktopMenuButton[0].link_text}
          </Link>
        </div>

        <HamburgerButton
          menuIsOpen={menuIsOpen}
          toggleMenuIsOpen={toggleMenuIsOpen}
          contrastingColor={contrastingColor}
        />
      </InnerWrapper>
    </SecondaryHeaderWrapper>
  );
};

const HamburgerButton = ({
  menuIsOpen,
  toggleMenuIsOpen,
  contrastingColor,
}) => {
  return (
    <HamburgerButtonWrapper
      menuIsOpen={menuIsOpen}
      onClick={toggleMenuIsOpen}
      aria-label="Menu Button"
      contrastingColor={contrastingColor}
    >
      <div className="hamburger__bar hamburger__bar--top" />
      <div className="hamburger__bar hamburger__bar--middle" />
      <div className="hamburger__bar hamburger__bar--bottom" />
    </HamburgerButtonWrapper>
  );
};

const Header = ({ isHomepage }) => {
  const [menuIsOpen, toggleMenuIsOpen] = useToggle(false);

  const staticData = useStaticQuery(graphql`
    query {
      prismicGlobalLayout {
        _previewable
        data {
          desktop_menu_link {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
          desktop_menu_button {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const pageDocument = data.prismicGlobalLayout;

  const {
    desktop_menu_link: desktopMenuLink,
    desktop_menu_button: desktopMenuButton,
  } = pageDocument.data;

  useEffect(() => {
    function handleKeyDown(e) {
      if (menuIsOpen && e.code === 'Escape') {
        toggleMenuIsOpen(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [menuIsOpen, toggleMenuIsOpen]);

  // Lock page to prevent from scrolling while menu is open
  useEffect(() => {
    if (menuIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  }, [menuIsOpen]);

  return (
    <>
      <Menu menuIsOpen={menuIsOpen} toggleMenuIsOpen={toggleMenuIsOpen} />

      <SecondaryHeader
        isHomepage={isHomepage}
        menuIsOpen={menuIsOpen}
        toggleMenuIsOpen={toggleMenuIsOpen}
        desktopMenuLink={desktopMenuLink}
        desktopMenuButton={desktopMenuButton}
      />

      <HomepageHeader
        isHomepage={isHomepage}
        desktopMenuLink={desktopMenuLink}
        desktopMenuButton={desktopMenuButton}
      />
    </>
  );
};

export default Header;

/**
 * Styled Components
 *
 */
const HomepageHeaderWrapper = styled.header`
  display: none;
  z-index: 1002;
  position: absolute;
  top: 0;
  left: var(--page-padding);
  right: var(--page-padding);
  border-bottom: 0.1rem solid var(--color-navy);
  max-width: var(--width-max);
  background-color: var(--color-snow);
  pointer-events: ${props => (props.isHomepage ? 'auto' : 'none')};
  visibility: ${props => (props.isHomepage ? 'visible' : 'hidden')};
  opacity: ${props => (props.isHomepage ? 1 : 0)};
  transform: translateY(${props => (props.isHomepage ? 0 : '-100%')});
  transition: all 300ms var(--ease-cubic-in-out) 100ms;

  @media (${bp.min.sm_md}) {
    display: block;
  }

  &:after {
    content: '';
    width: 100vw;
    height: 100%;
    background-color: var(--color-snow);
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    z-index: -1;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-m) 0 var(--space-xs);
  }

  a {
    font-family: var(--font-family-national-compressed);
    font-weight: var(--font-weight-extrabold);
    font-size: clamp(3rem, 4.5vw, 6rem);
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    display: block;
  }

  .button a {
    font-size: clamp(3rem, 4.5vw, 5rem);
    padding: var(--space-xxs) var(--space-s);
    border: 0.1rem solid currentColor;
    border-radius: 0.5rem;
    transition: all 150ms var(--ease-cubic-in-out);

    &:hover {
      color: var(--color-snow);
      background-color: var(--color-great-blue);
      border-color: var(--color-great-blue);
    }
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: var(--space-m) var(--page-padding);

  @media (${bp.min.sm_md}) {
    align-items: baseline;
    padding: var(--space-m) var(--page-padding) 0;
  }

  &:after {
    content: '';
    position: absolute;
    left: var(--page-padding);
    right: var(--page-padding);
    bottom: 0;
    border-bottom: 0.4rem solid ${props => props.contrastingColor};

    ${props =>
      props.menuIsOpen &&
      `
      border-color: var(--color-snow) !important;
    `};

    @media (${bp.min.sm_md}) {
      border-width: 0.1rem;
    }
  }

  ul {
    display: none;
    margin-right: auto;

    @media (${bp.min.sm_md}) {
      display: flex;
    }

    li {
      margin-left: 3.6rem;

      @media (${bp.min.md}) {
        margin-left: 5.6rem;
      }

      a {
        padding-bottom: var(--space-s);
        border-bottom: 0.5rem solid transparent;

        &.is-active {
          border-color: var(--color-navy);
        }
      }
    }
  }

  a {
    font-family: var(--font-family-copy);
    font-weight: var(--font-weight-bold);
    font-size: 2rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    display: block;
    color: ${props => props.contrastingColor};

    &:hover {
      color: var(--color-great-blue);
    }

    svg {
      fill: ${props =>
        props.menuIsOpen
          ? 'var(--color-snow) !important'
          : props.contrastingColor};
      transition: fill 150ms var(--ease-cubic-in-out);

      @media (hover: hover) {
        &:hover {
          fill: var(--color-great-blue);
        }
      }
    }
  }

  .button a {
    padding: var(--space-xxs) var(--space-xs);
    border: 0.1rem solid currentColor;
    border-radius: 0.5rem;
    margin-left: auto;
    display: none;
    transition: all 150ms var(--ease-cubic-in-out);

    @media (${bp.min.sm_md}) {
      display: flex;
    }

    &:hover {
      color: var(--color-snow);
      background-color: var(--color-great-blue);
      border-color: var(--color-great-blue);
    }
  }
`;

const SecondaryHeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: var(--width-max);
  margin: 0 auto;
  z-index: 1001;
  transition: transform 250ms var(--ease-cubic-in-out);

  &.headroom--top {
    @media (${bp.min.sm_md}) {
      display: ${props => (props.isHomepage ? 'none' : '')};
    }
  }

  &.headroom--not-top {
    ${InnerWrapper}:after {
      border-color: var(--color-navy);
    }

    a {
      color: var(--color-navy);
    }

    svg {
      fill: var(--color-navy);
    }

    .hamburger__bar {
      background-color: var(--color-navy);
    }
  }

  &.headroom--pinned {
    transform: translateY(0);
  }

  &.headroom--unpinned {
    transform: translateY(-100%);
  }
`;

const HamburgerButtonWrapper = styled.button`
  display: flex;
  position: relative;
  width: 4rem;
  height: 2.6rem;
  cursor: pointer;
  margin-left: auto;
  -webkit-tap-highlight-color: transparent;

  @media (${bp.min.sm_md}) {
    display: none;
  }

  &:focus {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      .hamburger__bar {
        background-color: var(--color-great-blue);
      }
    }
  }

  .hamburger__bar {
    position: absolute;
    width: 100%;
    height: 0.4rem;
    background-color: ${props =>
      props.menuIsOpen
        ? 'var(--color-snow) !important'
        : props.contrastingColor};
    transition: transform 200ms var(--ease-cubic-in-out) 0ms;
  }

  .hamburger__bar--top {
    top: 0;
    transform: ${props =>
      props.menuIsOpen
        ? 'translate(0.8rem, -0.2rem) rotate(45deg) scaleX(1)'
        : ''};
    transform-origin: 0% 0%;
  }

  .hamburger__bar--middle {
    top: 1.1rem;
    transform: ${props => (props.menuIsOpen ? 'scaleX(0)' : '')};
  }

  .hamburger__bar--bottom {
    bottom: 0;
    transform: ${props =>
      props.menuIsOpen
        ? 'translate(0.8rem, 0.3rem) rotate(-45deg) scaleX(1)'
        : ''};
    transform-origin: 0% 100%;
  }
`;
