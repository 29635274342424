import React, { useState, useRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller, Keyboard, Navigation, Pagination } from 'swiper';

import bp from '../../assets/js/breakpoints';
import { getContrastingColor } from '../../assets/js/utilities';

import ArrowIcon from '../Icons/ArrowIcon';
import Image from '../Image';

import 'swiper/css';
import 'swiper/css/keyboard';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const RecentStoriesSliderMobile = ({ stories }) => {
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [sectionBgColor, setSectionBgColor] = useState(null);
  const [sectionTextColor, setSectionTextColor] = useState(null);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <Wrapper sectionBgColor={sectionBgColor} className="full-width-container">
      <Swiper
        centeredSlides={true}
        loop={true}
        loopedSlides={stories.length}
        enabled={false}
        slidesPerView={3}
        spaceBetween={32}
        modules={[Controller]}
        onSwiper={setControlledSwiper}
      >
        {stories.map((story, i) => (
          <SwiperSlide key={i}>
            <StoryNumber sectionTextColor={sectionTextColor}>
              Nº {story.node.data.story_number}
            </StoryNumber>
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        centeredSlides={true}
        keyboard={true}
        loop={true}
        loopedSlides={stories.length}
        pagination={{
          el: '.custom-pagination',
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
          },
        }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={swiper => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        slidesPerView={1.25}
        spaceBetween={8}
        modules={[Controller, Keyboard, Navigation, Pagination]}
        controller={{ control: controlledSwiper }}
        className="story-swiper"
        onTransitionStart={e => {
          const storyColor = stories[e.realIndex]?.node.data.story_color;

          setSectionBgColor(storyColor);

          // Fixing a weird Swiper bug where display: none breaks initialization
          setSectionTextColor(
            getContrastingColor(storyColor ? storyColor : '#000000')
          );
        }}
      >
        {stories.map((story, i) => {
          const { url, short_firstpub_date } = story.node;
          const { title, featured_image, short_custompub_date } =
            story.node.data;

          return (
            <SwiperSlide key={i}>
              <StorySlide to={url}>
                <Image
                  image={featured_image}
                  aspectRatio={1 / 1}
                  loading="eager"
                />

                <StoryInfo sectionTextColor={sectionTextColor}>
                  <p className="story-date">
                    {short_custompub_date
                      ? short_custompub_date
                      : short_firstpub_date}
                  </p>
                  <h3>{title}</h3>
                </StoryInfo>
              </StorySlide>
            </SwiperSlide>
          );
        })}

        <CustomNavigation sectionTextColor={sectionTextColor}>
          <div className="custom-navigation__wrapper">
            <button ref={navigationPrevRef} aria-label="Arrow Left">
              <ArrowIcon direction="left" size={16} stroke={sectionTextColor} />
            </button>

            <div className="custom-pagination"></div>

            <button ref={navigationNextRef} aria-label="Arrow Right">
              <ArrowIcon
                direction="right"
                size={16}
                stroke={sectionTextColor}
              />
            </button>
          </div>
        </CustomNavigation>
      </Swiper>
    </Wrapper>
  );
};

export default RecentStoriesSliderMobile;

/**
 * Styled Components
 *
 */

const StoryInfo = styled.div`
  color: ${props => props.sectionTextColor};
  padding: var(--space-m) var(--space-xxs) var(--space-m) 0;
  opacity: 0;
  transition: opacity 300ms var(--ease-cubic-in-out) 50ms;

  .story-date {
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    margin-bottom: var(--space-xxs);
  }

  h3 {
    font-size: clamp(3.6rem, 3.37rem + 0.98vw, 4.4rem);
    line-height: 1.1;
    letter-spacing: -0.2rem;
    transform: translateY(0.4rem);
    transition: transform 250ms var(--ease-cubic-in-out) 100ms;
  }
`;

const Wrapper = styled.div`
  background-color: ${props =>
    props.sectionBgColor ? props.sectionBgColor : 'var(--color-navy)'};
  padding: var(--space-xl) 0 var(--space-m);
  transition: background-color 200ms var(--ease-cubic-in-out);

  @media (${bp.min.sm}) {
    display: none;
  }

  .story-swiper {
    .swiper-wrapper {
      margin-bottom: var(--space-l);
    }

    .swiper-slide-active {
      ${StoryInfo} {
        opacity: 1;

        h3 {
          transform: translateY(0);
        }
      }
    }
  }
`;

const CustomNavigation = styled.div`
  --swiper-pagination-bullet-size: 1rem;
  --swiper-pagination-bullet-horizontal-gap: 0.5rem;
  --swiper-pagination-bullet-inactive-color: ${props => props.sectionTextColor};
  --swiper-pagination-bullet-inactive-opacity: 1;

  display: flex;
  justify-content: center;

  .custom-navigation__wrapper {
    display: inline-flex;
    align-items: center;
  }

  button {
    --size: 1.6rem;

    width: var(--size);
    height: var(--size);
    cursor: pointer;
  }

  .custom-pagination {
    display: flex;
    margin: 0 var(--space-l);
  }

  .swiper-pagination-bullet {
    background: transparent;
    border: 0.1rem solid ${props => props.sectionTextColor};
    transition: all 200ms var(--ease-cubic-in-out);
  }

  .swiper-pagination-bullet-active {
    background: ${props => props.sectionTextColor};
  }
`;

const StoryNumber = styled.div`
  color: ${props => props.sectionTextColor};
  font-size: clamp(3.6rem, 2.336rem + 5.39vw, 8rem);
  line-height: 1;
  font-weight: var(--font-weight-bold);
  text-align: center;
  opacity: 0.3;
  margin-bottom: var(--space-s);
  white-space: nowrap;

  .swiper-slide-active & {
    opacity: 1;
  }
`;

const StorySlide = styled(GatsbyLink)`
  img {
    display: flex;
  }
`;
