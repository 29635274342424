import React from 'react';

const LinkIcon = ({ fill = 'var(--color-navy)' }) => {
  return (
    <svg
      width="21"
      height="11"
      viewBox="0 0 21 11"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Link</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.31818C0 2.38103 2.38103 0 5.31818 0H8.77273V2H5.31818C3.4856 2 2 3.4856 2 5.31818C2 7.15076 3.4856 8.63636 5.31818 8.63636H8.77273V10.6364H5.31818C2.38103 10.6364 0 8.25533 0 5.31818ZM15.6818 2H12.2273V0H15.6818C18.619 0 21 2.38103 21 5.31818C21 8.25533 18.619 10.6364 15.6818 10.6364H12.2273V8.63636H15.6818C17.5144 8.63636 19 7.15076 19 5.31818C19 3.4856 17.5144 2 15.6818 2ZM15 6.31818H6V4.31818H15V6.31818Z"
      />
    </svg>
  );
};

export default LinkIcon;
