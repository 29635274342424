import React, { useContext } from 'react';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

const ButtonIcon = ({ icon, contrastingColor, reverseContrastingColor }) => {
  if (icon === 'headphones') {
    return (
      <IconWrapper reverseContrastingColor={reverseContrastingColor}>
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill={contrastingColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M21.3487 8.80581C20.813 5.7798 18.6879 3.00521 15.7859 1.55057C14.4108 0.85917 12.8929 0.5 11.4107 0.5H10.5178C7.46402 0.5 4.46385 2.00851 2.49946 4.54066C-1.00966 9.06621 0.633284 15.0284 3.34772 19.096C3.87454 19.8862 4.71387 20.389 5.65142 20.4788C5.74964 20.4878 5.85679 20.4968 5.95501 20.4968C6.77648 20.4968 7.56224 20.1735 8.15156 19.5809C9.23198 18.4944 9.37484 16.7973 8.49086 15.5492L6.40146 12.604C6.1068 12.191 5.67821 11.9036 5.19604 11.7959L2.28516 11.1314C2.17801 9.31763 2.56196 7.40504 3.91918 5.65409C5.54427 3.56192 8.01762 2.30483 10.5267 2.30483H11.4196C12.6339 2.30483 13.8751 2.60114 15.0002 3.16684C17.4021 4.37006 19.1611 6.65079 19.5986 9.12906C19.7147 9.8025 19.7504 10.467 19.7058 11.1225L16.7413 11.7959C16.2502 11.9036 15.8216 12.191 15.527 12.604L13.4376 15.5492C12.5536 16.7973 12.6875 18.4944 13.7679 19.5809C14.4287 20.2454 15.3484 20.5686 16.277 20.4878C17.2146 20.398 18.0539 19.8952 18.5807 19.105C19.9379 17.0757 22.0898 13.044 21.3487 8.81479V8.80581ZM4.79423 13.5379C4.85673 13.5469 4.91031 13.5828 4.94602 13.6366L7.03543 16.5818C7.41045 17.1116 7.35687 17.8389 6.89256 18.3059C6.60683 18.5932 6.22288 18.7369 5.82107 18.692C5.41926 18.656 5.05317 18.4316 4.82995 18.0993C4.25848 17.2373 3.11556 15.3247 2.56196 13.035L4.79423 13.5379V13.5379ZM17.0896 18.0993C16.8663 18.4405 16.5002 18.656 16.0984 18.692C15.7055 18.7279 15.3127 18.5932 15.0269 18.3059C14.5626 17.8389 14.5091 17.1206 14.8841 16.5818L16.9735 13.6366C17.0092 13.5828 17.0628 13.5469 17.1253 13.5379L19.4022 13.0171C18.8932 15.1182 17.8485 16.959 17.0896 18.0904V18.0993Z" />
        </svg>
      </IconWrapper>
    );
  }

  return (
    <IconWrapper reverseContrastingColor={reverseContrastingColor}>
      <svg
        width="10"
        height="14"
        viewBox="0 0 8 14"
        fill={contrastingColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 12.0793V1.92066C0 1.11163 0.910686 0.63748 1.57346 1.10142L8.82967 6.18077C9.39839 6.57887 9.39838 7.42113 8.82967 7.81923L1.57346 12.8986C0.910685 13.3625 0 12.8884 0 12.0793Z" />
      </svg>
    </IconWrapper>
  );
};

const ListenWatchButton = ({
  story_color,
  spotify_id,
  youtube_id,
  contrastingColor,
  reverseContrastingColor,
}) => {
  const { setCurrentSpotifyEpisode, setStoryColor } = useContext(GlobalContext);

  function handleClick() {
    setCurrentSpotifyEpisode(spotify_id);
    setStoryColor(story_color);
  }

  if (spotify_id) {
    return (
      <ButtonWrapper
        contrastingColor={contrastingColor}
        reverseContrastingColor={reverseContrastingColor}
        onClick={handleClick}
        className="spotify-button"
      >
        <ButtonIcon
          contrastingColor={contrastingColor}
          reverseContrastingColor={reverseContrastingColor}
          icon={'headphones'}
        />
        Listen Now
      </ButtonWrapper>
    );
  }

  if (youtube_id) {
    return (
      <ButtonWrapper
        as="a"
        href={`https://www.youtube.com/watch?v=${youtube_id}`}
        target="_blank"
        rel="noreferrer"
        contrastingColor={contrastingColor}
        reverseContrastingColor={reverseContrastingColor}
      >
        <ButtonIcon
          contrastingColor={contrastingColor}
          reverseContrastingColor={reverseContrastingColor}
        />
        Watch Video
      </ButtonWrapper>
    );
  }
};

export default ListenWatchButton;

/**
 * Styled Components
 *
 */
const ButtonWrapper = styled.button`
  background-color: ${props => props.contrastingColor};
  padding: var(--space-xxs) 2rem var(--space-xxs);
  color: ${props => props.reverseContrastingColor} !important;
  border-radius: 10rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  font-size: 1.5rem;
  line-height: 1;
  text-align: left;
  transition: all 250ms var(--ease-cubic-in-out);
  margin-right: var(--space-s);

  @media (min-width: 340px) {
    padding: var(--space-xxs) 2rem var(--space-xxs) var(--space-xxs);
  }

  &:hover {
    background-color: ${props => props.reverseContrastingColor};
    color: ${props => props.contrastingColor} !important;
  }
`;

const IconWrapper = styled.div`
  background-color: ${props => props.reverseContrastingColor};
  border-radius: 10rem;
  display: none;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: var(--space-xs);

  @media (min-width: 340px) {
    display: flex;
  }

  svg {
    overflow: visible;
  }
`;
