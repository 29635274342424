import React from 'react';
import styled from 'styled-components';

import bp from '../../assets/js/breakpoints';

const Embed = ({ slice }) => {
  const data = slice.primary;

  const embedWidths = {
    'Full Width': 'full',
    'Medium Width': 'popout',
    'Content Width': 'content',
  };

  return (
    <EmbedWrapper
      embedWidth={embedWidths[data.embed_width]}
      dangerouslySetInnerHTML={{ __html: data.embed.text }}
    />
  );
};

export default Embed;

/**
 * Styled Components
 *
 */
const EmbedWrapper = styled.div`
  width: 100%;
  margin: var(--space-m) 0;
  grid-column: ${props => props.embedWidth};

  @media (${bp.min.sm_md}) {
    margin: var(--space-xl) 0;
  }

  iframe {
    width: 100%;
  }
`;
