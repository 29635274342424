import React from 'react';

import FooterDesktop from './FooterDesktop';
import FooterMobile from './FooterMobile';

const Footer = () => {
  return (
    <>
      <FooterDesktop />
      <FooterMobile />
    </>
  );
};

export default Footer;
