import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

const Image = ({ image, aspectRatio, loading }) => {
  const imageData = getImage(image);

  return (
    <Wrapper aspectRatio={aspectRatio} className="image-wrapper">
      {imageData ? (
        <GatsbyImage
          className="image"
          image={imageData}
          alt={image.alt ? image.alt : ' '}
          loading={loading}
        />
      ) : null}
    </Wrapper>
  );
};

export default Image;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  width: 100%;

  .image {
    overflow: hidden;
    max-width: 100%;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }

    /* If aspect ratio is set, apply supported aspect ratio method */
    ${props =>
      props.aspectRatio &&
      `
      --aspect-ratio: ${props.aspectRatio};

      @supports not (aspect-ratio: 1/1) {
        height: 0;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
      }

      @supports (aspect-ratio: 1/1) {
        aspect-ratio: var(--aspect-ratio);
      }
    `}
  }
`;
