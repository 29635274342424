import React from 'react';
import styled from 'styled-components';

import bp from '../../assets/js/breakpoints';

import Image from '../Image';

const LargeImage = ({ slice }) => {
  const data = slice.primary;

  return (
    <Wrapper className="image-slice">
      <figure>
        <Image image={data.image} />

        {data.image.alt !== '' ||
          (data.image.alt !== ' ' && (
            <figcaption>
              ↑ <span>{data.image.alt}</span>
            </figcaption>
          ))}
      </figure>
    </Wrapper>
  );
};

export default LargeImage;

/**
 * Styled Components
 *
 */

const Wrapper = styled.div`
  margin: var(--space-l) 0;
  grid-column: popout;

  @media (${bp.min.sm}) {
    margin: 5.6rem 0;
  }

  figcaption {
    font-size: 1.2rem;
    display: flex;
    margin-top: var(--space-xxs);

    span {
      margin-left: var(--space-xxs);
    }
  }
`;
