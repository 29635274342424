import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

import bp from '../assets/js/breakpoints';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  :root {
    font-size: 62.5%;

    --color-snow: #F6F5ED;
    --color-baybee-blue: #C5E2E3;
    --color-navy: #172A35;
    --color-navy-light: #7C97A7;
    --color-great-blue: #3D90E9;
    --color-ice: #E8FFFF;
    --color-white: #FFFFFF;

    --font-family-copy: 'Copy', 'Helvetica Neue', Helvetica, sans-serif;
    --font-family-national-compressed: 'National 2 Compressed', 'Helvetica Neue', Helvetica, sans-serif;
    --font-family-national-condensed: 'National 2 Condensed', 'Helvetica Neue', Helvetica, sans-serif;
    --font-weight-normal: 400;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;

    --font-size: 2.0rem;

    --width-max: 180rem;
    --page-padding: clamp(var(--space-s), 1.035rem + 2.82vw, var(--space-xl));

    --spacing: .8rem;
    --space-xxxs: calc(var(--spacing) * 0.5);
    --space-xxs: calc(var(--spacing) * 1);
    --space-xs: calc(var(--spacing) * 1.5);
    --space-s: calc(var(--spacing) * 2);
    --space-m: calc(var(--spacing) * 3);
    --space-l: calc(var(--spacing) * 4);
    --space-xl: calc(var(--spacing) * 5);
    --space-xxl: calc(var(--spacing) * 9);
    --space-xxxl: calc(var(--spacing) * 18);

    --ease-cubic-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-quart-in-out: cubic-bezier(0.77, 0, 0.18, 1);
    --ease-bounce-in-out: cubic-bezier(0.68, -0.4, 0.27, 1.4);
  }

  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  body {
    background-color: var(--color-snow);
    font-family: var(--font-family-copy);
    color: var(--color-navy);
    font-size: var(--font-size);
    line-height: 1.6;
    letter-spacing: -.06rem;
    text-align: left;
    font-variant-numeric: lining-nums;
    font-feature-settings: 'lnum';
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (${bp.min.sm}) {
      --font-size: 1.6rem;
    }
  }

  ::selection {
    background-color: var(--color-navy);
    color: var(--color-baybee-blue);
  }

  a {
    color: var(--color-navy);
    transition: color 150ms var(--ease-cubic-in-out);
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @media (hover: hover) {
      &:hover {
        color: var(--color-great-blue);
      }
    }
  }

  strong {
    font-weight: var(--font-weight-bold);
  }

  em {
    font-style: italic;
  }

  button {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  button,
  input {
    font-family: var(--font-family-copy);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-weight: normal;
  }

  #main {
    position: relative;
    z-index: 10;
  }

  .skip-to-main {
    position: absolute;
    top: 0;
    left: -9999rem;
    z-index: 1000;
    transform: translateY(-100%);
    padding: var(--space-xs) var(--space-m);
    background-color: var(--color-great-blue);
    color: var(--color-snow);
    transition: transform 500ms var(--ease-cubic-in-out);

    &:focus {
      left: 0;
      transform: translateY(0);
      outline-color: var(--color-navy);
    }

    &:hover {
      color: var(--color-snow);
    }
  }

  .sr-only {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: .1rem;
    height: .1rem;
    overflow: hidden;
  }

  .no-break {
    white-space: nowrap;
  }

  .full-width-container {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`;
