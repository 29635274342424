import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { GlobalContext } from '../components/GlobalContext';

import Seo from '../components/Seo';
import RecentStoriesSlider from '../components/RecentStoriesSlider';
import SliceZone from '../components/SliceZone';
import bp from '../assets/js/breakpoints';
import Image from '../components/Image';
import ShareDesktop from '../components/ShareDesktop';
import ShareMobile from '../components/ShareMobile';

const PrismicBasicPage = ({ data }) => {
  const document = data.prismicBasicPage;
  const { short_firstpub_date, full_firstpub_date, url } = document;
  const {
    title,
    share_widget,
    recent_stories,
    published_date,
    description,
    full_custompub_date,
    short_custompub_date,
    author_name,
    featured_image,
    body,
  } = document.data;

  const { setStoryHeaderColor, setPageBgColor } = useContext(GlobalContext);

  useEffect(() => {
    setPageBgColor('var(--color-snow)');
    setStoryHeaderColor('var(--color-snow)');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <FeaturedImage>
        {featured_image && <Image image={featured_image} loading="eager" />}
      </FeaturedImage>

      <Intro showPublishedDate={published_date}>
        {published_date && (
          <p className="intro-date intro-date--mobile">
            {short_custompub_date ? short_custompub_date : short_firstpub_date}
          </p>
        )}

        <Title>{title}</Title>

        {description.richText.length > 0 && (
          <IntroDescription>
            <PrismicRichText field={description.richText} />
          </IntroDescription>
        )}

        <PageDetails>
          {author_name && (
            <p className="intro-author">Written By: {author_name}</p>
          )}

          {published_date && (
            <p className="intro-date intro-date--desktop">
              {full_custompub_date ? full_custompub_date : full_firstpub_date}
            </p>
          )}
        </PageDetails>
      </Intro>

      {body.length > 0 && (
        <Body>
          <SliceZone slices={body} />

          {share_widget && (
            <>
              <ShareDesktop url={url} title={title} />
              <ShareMobile url={url} title={title} variant="basic-page" />
            </>
          )}
        </Body>
      )}

      {recent_stories && <RecentStoriesSlider variant="footer" />}
    </Wrapper>
  );
};

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({ data }) => {
  const document = data.prismicBasicPage;

  // If there's no provided meta description/image, use the page
  //  description/image as a fallback
  const description =
    document.data.meta_description || document.data.description.text;
  const image =
    document.data.meta_image.url || document.data.featured_image.url;

  return (
    <Seo
      title={data.prismicBasicPage.data.title}
      description={description}
      image={image}
    />
  );
};

export default withPrismicPreview(PrismicBasicPage);

/**
 * Styled Components
 *
 */

const Wrapper = styled.div`
  padding-top: 12rem;
`;

const FeaturedImage = styled.div``;

const Intro = styled.div`
  position: relative;
  width: 100%;
  max-width: 88rem;
  padding: var(--space-l) 0 var(--space-xxs);
  border-bottom: 0.1rem solid ${props => props.contrastingColor};
  margin-bottom: var(--space-xl);

  @media (${bp.min.xs_sm}) {
    border-bottom: 0;
    padding: var(--space-xl) 0 0;
    margin: 0 auto 4.8rem;
  }

  &:after {
    content: '';
    width: 100%;
    border-bottom: 0.1rem solid ${props => props.contrastingColor};
    position: absolute;
    bottom: 0.4rem;

    @media (${bp.min.xs_sm}) {
      display: none;
    }
  }

  .intro-author,
  .intro-date {
    line-height: 1;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    color: var(--color-navy);
    text-transform: uppercase;

    @media (${bp.min.xs_sm}) {
      opacity: 0.5;
    }
  }

  .intro-date {
    font-size: 2.2rem;

    @media (${bp.min.xs_sm}) {
      font-size: 1.2rem;
    }
  }

  .intro-date--mobile {
    margin-bottom: var(--space-m);

    @media (${bp.min.xs_sm}) {
      display: none;
    }
  }

  .intro-date--desktop {
    display: none;

    @media (${bp.min.xs_sm}) {
      display: block;
    }
  }

  .intro-author {
    font-size: 1.2rem;
    margin-bottom: var(--space-xs);

    @media (${bp.min.xs_sm}) {
      margin-bottom: 0;
    }

    ${props =>
      props.showPublishedDate &&
      `
      &:after {
        @media (${bp.min.xs_sm}) {
          content: '•';
          margin-right: var(--space-xs);
          padding-left: var(--space-xs);
        }
      }
    `}
  }
`;

const Title = styled.h1`
  font-size: clamp(4.4rem, 8vw, 9rem);
  line-height: 1.1;
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--space-m);
  width: 100%;
  letter-spacing: -0.04em;

  @media (${bp.min.sm_md}) {
    line-height: 0.88;
    margin-bottom: var(--space-l);
  }
`;

const PageDetails = styled.div`
  width: 100%;
  display: flex;
`;

const IntroDescription = styled.div`
  font-size: clamp(2.8rem, 5vw, 4.2rem);
  line-height: 1.15;
  font-weight: var(--font-weight-bold);
  letter-spacing: -0.05em;
  margin-bottom: var(--space-l);
`;

const Body = styled.article`
  --full: minmax(0, 1fr);
  --content: min(65ch, 100%);
  --popout: minmax(0, 1.5fr);

  display: grid;
  grid-template-columns:
    [full-start] var(--full)
    [popout-start] var(--popout)
    [content-start] var(--content) [content-end]
    var(--popout) [popout-end]
    var(--full) [full-end];
  padding-bottom: var(--space-xxl);

  @media (${bp.min.xs_sm}) {
    border-bottom: 0.1rem solid var(--color-navy);
  }

  .text-slice + .text-slice {
    margin-top: var(--space-m);
  }

  .image-slice + .text-slice {
    margin-top: 0;
  }

  .image-slice + .image-slice {
    margin-top: 0;
  }
`;

/**
 * GraphQL
 *
 */
export const query = graphql`
  query ($id: String!) {
    prismicBasicPage(id: { eq: $id }) {
      _previewable
      url
      full_firstpub_date: first_publication_date(formatString: "MMMM DD, YYYY")
      short_firstpub_date: first_publication_date(formatString: "MM.DD.YY")
      data {
        title
        description {
          richText
          text
        }
        share_widget
        recent_stories
        published_date
        author_name
        full_custompub_date: publish_date(formatString: "MMMM DD, YYYY")
        short_custompub_date: publish_date(formatString: "MM.DD.YY")
        featured_image {
          alt
          gatsbyImageData(width: 1400)
          url
        }
        body {
          ... on PrismicBasicPageDataBodyText {
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicBasicPageDataBodyLargeImage {
            slice_type
            primary {
              image {
                alt
                gatsbyImageData(width: 1200)
              }
            }
          }
          ... on PrismicBasicPageDataBodyImage2Up {
            slice_type
            primary {
              image_1 {
                alt
                gatsbyImageData(width: 800)
              }
              image_2 {
                alt
                gatsbyImageData(width: 800)
              }
            }
          }
          ... on PrismicBasicPageDataBodyImage3Up {
            slice_type
            primary {
              image_1 {
                alt
                gatsbyImageData(width: 800)
              }
              image_2 {
                alt
                gatsbyImageData(width: 800)
              }
              image_3 {
                alt
                gatsbyImageData(width: 800)
              }
            }
          }
          ... on PrismicBasicPageDataBodyQuote {
            slice_type
            primary {
              quote {
                richText
              }
              attribution
            }
          }
          ... on PrismicBasicPageDataBodyYoutube {
            slice_type
            primary {
              youtube_id
              embed_width
            }
          }
          ... on PrismicBasicPageDataBodyEmbed {
            slice_type
            primary {
              embed {
                text
              }
              embed_width
            }
          }
        }
        meta_description
        meta_image {
          url
        }
      }
    }
  }
`;
