import React from 'react';
import styled from 'styled-components';

import bp from '../../assets/js/breakpoints';

import Image from '../Image';

const Image2Up = ({ slice }) => {
  const data = slice.primary;

  return (
    <Wrapper className="image-slice">
      <figure>
        <Image image={data.image_1} />

        {data.image_1.alt !== '' ||
          (data.image.alt !== ' ' && (
            <figcaption>
              ↑ <span>{data.image_1.alt}</span>
            </figcaption>
          ))}
      </figure>

      <figure>
        <Image image={data.image_2} />

        {data.image_2.alt !== '' && (
          <figcaption>
            ↑ <span>{data.image_2.alt}</span>
          </figcaption>
        )}
      </figure>
    </Wrapper>
  );
};

export default Image2Up;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  --grid-layout-gap: 4rem;
  --grid-column-count: 2;
  --grid-item--min-width: 30rem;
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
  grid-column: full;
  margin: var(--space-l) 0;

  @media (${bp.min.sm}) {
    --grid-layout-gap: 2rem;
    margin: 5.6rem 0;
  }

  figure {
    padding-bottom: var(--space-l);
  }

  figcaption {
    font-size: 1.2rem;
    display: flex;
    margin-top: var(--space-xxs);

    span {
      margin-left: var(--space-xxs);
    }
  }

  .image-wrapper,
  .image {
    height: 100%;
  }
`;
