import React from 'react';
import styled from 'styled-components';

import bp from '../../assets/js/breakpoints';

import Image from '../Image';

const Image3Up = ({ slice }) => {
  const data = slice.primary;

  return (
    <Wrapper className="image-slice">
      <figure>
        <Image image={data.image_1} />

        {data.image_1.alt !== '' ||
          (data.image.alt !== ' ' && (
            <figcaption>
              ↑ <span>{data.image_1.alt}</span>
            </figcaption>
          ))}
      </figure>

      <figure>
        <Image image={data.image_2} />

        {data.image_2.alt !== '' && (
          <figcaption>
            ↑ <span>{data.image_2.alt}</span>
          </figcaption>
        )}
      </figure>

      <figure>
        <Image image={data.image_3} />

        {data.image_3.alt !== '' && (
          <figcaption>
            ↑ <span>{data.image_3.alt}</span>
          </figcaption>
        )}
      </figure>
    </Wrapper>
  );
};

export default Image3Up;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  --grid-layout-gap: 2rem;
  --grid-column-count: 1;

  display: grid;
  grid-template-columns: repeat(var(--grid-column-count), 1fr);
  grid-gap: var(--grid-layout-gap);
  margin: var(--space-l) 0;
  grid-column: popout;

  @media (${bp.min.xs_sm}) {
    --grid-column-count: 3;
  }

  @media (${bp.min.sm}) {
    margin: 5.6rem 0;
  }

  figure {
    padding-bottom: var(--space-l);
  }

  figcaption {
    font-size: 1.2rem;
    display: flex;
    margin-top: var(--space-xxs);

    span {
      margin-left: var(--space-xxs);
    }
  }

  .image-wrapper,
  .image {
    height: 100%;
  }
`;
