import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';

import { GlobalContext } from '../components/GlobalContext';
import { getContrastingColor } from '../assets/js/utilities';

const SpotifyPlayer = () => {
  const { storyColor, currentSpotifyEpisode } = useContext(GlobalContext);
  const [playerIsOpen, togglePlayerIsOpen] = useToggle(false);

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(storyColor);

  useEffect(() => {
    if (currentSpotifyEpisode !== null) {
      togglePlayerIsOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpotifyEpisode]);

  return (
    <Wrapper
      playerIsOpen={playerIsOpen}
      currentSpotifyEpisode={currentSpotifyEpisode}
      storyColor={storyColor}
    >
      {currentSpotifyEpisode && (
        <iframe
          style={{ borderRadius: '12px' }}
          src={`https://open.spotify.com/embed/episode/${currentSpotifyEpisode}?utm_source=generator`}
          width="100%"
          height="152"
          frameBorder="0"
          allowFullScreen=""
          title="Spotify Player"
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      )}

      <MinimizeButton
        playerIsOpen={playerIsOpen}
        currentSpotifyEpisode={currentSpotifyEpisode}
        onClick={togglePlayerIsOpen}
        storyColor={storyColor}
      >
        <span className="sr-only">
          {playerIsOpen ? 'Hide' : 'Show'} Spotify Player
        </span>
        <svg
          width="32"
          height="19"
          viewBox="0 0 32 19"
          fill="none"
          stroke={contrastingColor}
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Minimize</title>
          <path
            d="M1.85742 17.1421L15.9996 3L30.1417 17.1422"
            strokeWidth="3"
          />
        </svg>
      </MinimizeButton>
    </Wrapper>
  );
};

export default SpotifyPlayer;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transform: translateY(
    ${props =>
      props.playerIsOpen && props.currentSpotifyEpisode !== null ? 0 : '95%'}
  );
  background-color: ${props => props.storyColor};
  border-radius: 1.2rem;
  transition: all 300ms var(--ease-cubic-in-out) 100ms;
`;

const MinimizeButton = styled.button`
  position: absolute;
  right: var(--page-padding);
  top: -3.2rem;
  width: 4rem;
  height: 3.2rem;
  background-color: ${props => props.storyColor};
  opacity: ${props => (props.currentSpotifyEpisode !== null ? 1 : 0)};
  border-radius: 0.4rem 0.4rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  cursor: pointer;
  transition: all 150ms var(--ease-cubic-in-out) 100ms;

  svg {
    width: 1.6rem;
    transform: scale(${props => props.playerIsOpen && -1});
    transition: transform 150ms var(--ease-cubic-in-out) 100ms;
  }
`;
