import React from 'react';
import styled from 'styled-components';

const YouTube = ({ slice }) => {
  const data = slice.primary;

  const embedWidths = {
    'Full Width': 'full',
    'Medium Width': 'popout',
    'Content Width': 'content',
  };

  return (
    <YouTubeWrapper embedWidth={embedWidths[data.embed_width]}>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${data.youtube_id}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </YouTubeWrapper>
  );
};

export default YouTube;

/**
 * Styled Components
 *
 */
const YouTubeWrapper = styled.div`
  grid-column: ${props => props.embedWidth};
  position: relative;
  width: 100%;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
  margin: var(--space-l) 0;

  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
