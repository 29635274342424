import React from 'react';

import Text from './slices/Text';
import LargeImage from './slices/LargeImage';
import Image2Up from './slices/Image2Up';
import Image3Up from './slices/Image3Up';
import Quote from './slices/Quote';
import YouTube from './slices/YouTube';
import Embed from './slices/Embed';

const SliceZone = ({ slices, activeDoc }) => {
  const sliceComponents = {
    text: Text,
    large_image: LargeImage,
    quote: Quote,
    youtube: YouTube,
    embed: Embed,
    'image_2-up': Image2Up,
    'image_3-up': Image3Up,
  };

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type];

    if (SliceComponent) {
      return (
        <SliceComponent
          slice={slice}
          key={`slice-${index}`}
          activeDoc={activeDoc}
        />
      );
    }

    return null;
  });
};

export default SliceZone;
