import React, { useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

import { toKebabCase } from '../assets/js/utilities';
import bp from '../assets/js/breakpoints';
import Link from './Link';
import SocialIcon from './Icons/SocialIcon';

const Menu = ({ menuIsOpen, toggleMenuIsOpen }) => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicGlobalLayout {
        _previewable
        data {
          mobile_menu_link {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
          mobile_menu_button {
            link_text
            link_url {
              link_type
              target
              url
            }
          }
        }
        ...SocialItems
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const pageDocument = data.prismicGlobalLayout;

  const { mobile_menu_link, mobile_menu_button, social_menu } =
    pageDocument.data;

  const menuRef = useRef(null);

  useEffect(() => {
    if (!menuIsOpen) return;

    const q = gsap.utils.selector(menuRef);

    gsap.from(q('li'), {
      opacity: 0,
      y: 24,
      duration: 0.32,
      stagger: 0.02,
      ease: 'power2.out',
    });
  }, [menuIsOpen]);

  return (
    <Wrapper menuIsOpen={menuIsOpen} ref={menuRef}>
      <MenuInner>
        <MenuMain>
          {mobile_menu_link.map((item, i) => (
            <li key={i}>
              <Link url={item.link_url} onMenuClick={toggleMenuIsOpen}>
                {item.link_text}
              </Link>
            </li>
          ))}
        </MenuMain>

        <MenuSecondary>
          {mobile_menu_button.map((item, i) => (
            <li key={i}>
              <Link url={item.link_url} onMenuClick={toggleMenuIsOpen}>
                {item.link_text}
              </Link>
            </li>
          ))}
        </MenuSecondary>

        <SocialIcons>
          {social_menu.map(item => (
            <li key={item.link_text}>
              <a
                href={item.link_url.url}
                target="_blank"
                rel="noreferrer"
                aria-label={item.link_text}
              >
                <SocialIcon platform={toKebabCase(item.link_text)} />
              </a>
            </li>
          ))}
        </SocialIcons>

        <Copyright>
          <p>&copy; {new Date().getFullYear()} Hey Bay City</p>
        </Copyright>
      </MenuInner>
    </Wrapper>
  );
};

export default Menu;

/**
 * Styled Components
 *
 */
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-navy);
  z-index: 1000;
  opacity: ${props => (props.menuIsOpen ? 1 : 0)};
  pointer-events: ${props => (props.menuIsOpen ? 'auto' : 'none')};
  transition: all 100ms var(--ease-quart-in-out);

  @media (${bp.min.sm_md}) {
    display: none;
  }
`;

const MenuInner = styled.div`
  height: 100%;
  padding: 16rem var(--page-padding) var(--space-s);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
`;

const MenuMain = styled.ul`
  margin-bottom: auto;

  li {
    font-family: var(--font-family-national-condensed);
    font-size: 8rem;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: -0.1rem;
    text-align: center;

    & + li {
      margin-top: var(--space-l);
    }

    a {
      color: var(--color-snow);

      &.is-active {
        border-color: var(--color-ice);
      }
    }
  }
`;

const MenuSecondary = styled.ul`
  margin-top: var(--space-xxl);

  li {
    & + li {
      margin-top: var(--space-s);
    }

    a {
      font-size: 2.8rem;
      line-height: 1;
      letter-spacing: -0.15rem;
      font-weight: var(--font-weight-bold);
      border: 0.1rem solid var(--color-ice);
      color: var(--color-ice);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 7.6rem;
    }
  }
`;

const SocialIcons = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--space-l);

  li {
    @media (${bp.min.xs}) {
      padding: 0 var(--space-s);
    }

    a {
      display: flex;

      &:hover {
        svg {
          fill: var(--color-great-blue);
        }
      }

      svg {
        fill: var(--color-ice);
        transition: all 250ms var(--ease-cubic-in-out);
      }
    }
  }
`;

const Copyright = styled.div`
  margin-top: var(--space-l);

  p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: var(--font-weight-bold);
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    margin-bottom: var(--space-xxxs);
    color: var(--color-ice);
  }
`;
