import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { PrismicLink, PrismicProvider } from '@prismicio/react';
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews';

import { linkResolver } from './src/utilities/link-resolver';
import StoryTemplate from './src/pages/{PrismicStory.url}';
import BasicPageTemplate from './src/pages/{PrismicBasicPage.url}';

const richTextComponents = {
  hyperlink: ({ children, node }) => (
    <PrismicLink field={node.data}>{children}</PrismicLink>
  ),
};

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <GatsbyLink to={href} {...props} />
    )}
    linkResolver={linkResolver}
    richTextComponents={richTextComponents}
  >
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
          linkResolver,
          componentResolver: componentResolverFromMap({
            story: StoryTemplate,
            basic_page: BasicPageTemplate,
          }),
        },
      ]}
    >
      {element}
    </PrismicPreviewProvider>
  </PrismicProvider>
);

export const shouldUpdateScroll = () => {
  return false;
};
