import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

import { GlobalContext } from '../components/GlobalContext';

import { getContrastingColor } from '../assets/js/utilities';

import Image from './Image';
import useTrackLength from '../hooks/useTrackLength';

const ButtonIcon = ({ contrastingColor, reverseContrastingColor }) => {
  return (
    <IconWrapper
      contrastingColor={contrastingColor}
      reverseContrastingColor={reverseContrastingColor}
    >
      <svg
        width="10"
        height="14"
        viewBox="0 0 8 14"
        fill={contrastingColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 12.0793V1.92066C0 1.11163 0.910686 0.63748 1.57346 1.10142L8.82967 6.18077C9.39839 6.57887 9.39838 7.42113 8.82967 7.81923L1.57346 12.8986C0.910685 13.3625 0 12.8884 0 12.0793Z" />
      </svg>
    </IconWrapper>
  );
};

const Button = ({
  story_color,
  spotify_id,
  contrastingColor,
  reverseContrastingColor,
}) => {
  const { setStoryColor, setCurrentSpotifyEpisode } = useContext(GlobalContext);

  function handleClick() {
    setCurrentSpotifyEpisode(spotify_id);
    setStoryColor(story_color);
  }

  return (
    <ButtonWrapper
      contrastingColor={contrastingColor}
      reverseContrastingColor={reverseContrastingColor}
      onClick={handleClick}
    >
      <ButtonIcon
        contrastingColor={contrastingColor}
        reverseContrastingColor={reverseContrastingColor}
      />
      Listen Now
    </ButtonWrapper>
  );
};

const StoryCardFullImage = ({ story }) => {
  const { url, full_firstpub_date } = story.node;
  const {
    story_number,
    story_color,
    title,
    spotify_id,
    featured_image,
    full_custompub_date,
  } = story.node.data;

  // Get Track length from Spotify API
  const trackLength = useTrackLength(spotify_id);

  // Get contrasting colors based on the background color
  const contrastingColor = getContrastingColor(story_color);
  const reverseContrastingColor = getContrastingColor(contrastingColor);

  return (
    <Wrapper story_color={story_color} className="story-card">
      <ImageWrapper to={url}>
        <Image image={featured_image} />
      </ImageWrapper>

      <Content contrastingColor={contrastingColor}>
        <StoryNumber>Nº {story_number}</StoryNumber>

        <StoryDetails story_color={story_color}>
          <StoryInfo contrastingColor={contrastingColor}>
            <h3>{title}</h3>

            <div>
              <p>
                {full_custompub_date ? full_custompub_date : full_firstpub_date}
              </p>
              {trackLength && (
                <p>
                  {trackLength.hours !== 0 && trackLength.hours}{' '}
                  {trackLength.hours === 0
                    ? ''
                    : trackLength.hours === 1
                    ? 'Hour'
                    : 'Hours'}{' '}
                  {trackLength.minutes}{' '}
                  {trackLength.minutes === '01' ? 'Minute' : 'Minutes'}
                </p>
              )}
            </div>
          </StoryInfo>

          {trackLength && (
            <Button
              story_color={story_color}
              spotify_id={spotify_id}
              contrastingColor={contrastingColor}
              reverseContrastingColor={reverseContrastingColor}
            />
          )}
        </StoryDetails>
      </Content>
    </Wrapper>
  );
};

export default StoryCardFullImage;

/**
 * Styled Components
 *
 */
const Content = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  color: ${props => props.contrastingColor};
  transition: transform 400ms var(--ease-cubic-in-out);

  @media (hover: hover) {
    transform: translateY(80%);
  }
`;

const StoryNumber = styled.div`
  font-size: clamp(4.4rem, 1.174rem + 6.88vw, 8.4rem);
  color: var(--color-snow);
  font-weight: var(--font-weight-bold);
  line-height: 0.99;
  letter-spacing: -0.05em;
  margin-left: var(--space-l);
  display: flex;
  transition: all 150ms var(--ease-cubic-in-out) 0ms;

  @media (hover: hover) {
    transform: translateY(20%);
    opacity: 0;
  }
`;

const ImageWrapper = styled(GatsbyLink)`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 4rem;
    z-index: 100;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--space-s);
    right: var(--space-s);
    height: 75%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
    z-index: 10;
    transition: all 350ms var(--ease-cubic-in-out);

    @media (hover: hover) {
      transform: translateY(20%);
      opacity: 0;
    }
  }

  .image {
    height: 100%;
    clip-path: inset(var(--space-s));
    transition: all 350ms var(--ease-cubic-in-out);

    @media (hover: hover) {
      clip-path: inset(0);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Wrapper = styled.div`
  position: relative;
  aspect-ratio: 4 / 5;
  overflow: hidden;
  background-color: ${props => props.story_color};

  &:hover {
    .image {
      clip-path: inset(var(--space-s));
    }

    ${Content} {
      transform: translateY(0);
    }

    ${StoryNumber} {
      transform: translateY(0);
      opacity: 1;
      transition: all 250ms var(--ease-cubic-in-out) 200ms;
    }

    ${ImageWrapper} {
      &:after {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

const StoryDetails = styled.div`
  background-color: ${props => props.story_color};
  padding: var(--space-s);
`;

const StoryInfo = styled.div`
  display: flex;
  justify-content: space-between;

  &:hover {
    color: currentColor;
  }

  h3,
  div {
    width: 50%;
  }

  h3,
  p {
    color: ${props => props.contrastingColor};
    font-weight: var(--font-weight-bold);
    line-height: 1.2;
  }

  div {
    text-align: right;
  }
`;

const IconWrapper = styled.div`
  background-color: ${props => props.reverseContrastingColor};
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-right: var(--space-xxs);
  will-change: transform;
  transition: all 250ms var(--ease-cubic-in-out);

  svg {
    overflow: visible;
    width: 0.6rem;
    height: auto;
  }
`;

const ButtonWrapper = styled.button`
  color: ${props => props.contrastingColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  margin-top: var(--space-xs);
  transition: all 250ms var(--ease-cubic-in-out);

  &:hover {
    color: ${props => props.contrastingColor} !important;

    ${IconWrapper} {
      transform: scale(1.15);
    }
  }
`;
