import React from 'react';
import styled from 'styled-components';

const Logo = ({ fill, alignBaseline }) => {
  return (
    <LogoWrapper
      width="224"
      height="39"
      viewBox={`0 0 224 ${alignBaseline ? 28 : 39}`}
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
      className="logo"
    >
      <title>Hey, Bay City!</title>
      <path d="M12.94 20.1929V33.4569H21.14V0.39594H12.94V13.264H8.20004V0.39594H0V33.4569H8.20004V20.1929H12.94Z" />
      <path d="M31.0397 20.0444H38.1496V13.4124H31.0397V7.37437H39.382V0.39594H23.0293V33.4569H39.382V26.4784H31.0397V20.0444Z" />
      <path d="M39.6871 0.39594L46.8444 22.4201V33.4569H54.8074V22.4201L61.9173 0.39594H53.9068L50.9681 11.3833H50.8733L47.982 0.39594H39.6871Z" />
      <path d="M60.9341 24.3503C58.5168 24.3503 56.7156 26.1815 56.7156 28.8046C56.7156 31.1802 58.2324 32.764 60.318 32.764C60.6497 32.764 60.9815 32.665 61.1711 32.6155C61.2185 32.665 61.2185 32.764 61.2185 32.9124C61.2185 34.5457 59.8914 35.5355 57.5688 35.585V39C62.5457 39 65.8162 35.6345 65.8162 30.3388C65.8162 26.7754 63.9203 24.3503 60.9341 24.3503Z" />
      <path d="M93.2348 9.1066C93.2348 3.51396 90.059 0.39594 84.1815 0.39594H74.2277V33.4569H83.9445C90.533 33.4569 93.9932 30.2398 93.9932 24.3503C93.9932 20.1929 92.192 17.4708 88.8267 16.6294V16.5305C91.6706 15.7386 93.2348 13.1155 93.2348 9.1066ZM83.281 7.07741C84.8451 7.07741 85.5087 8.06726 85.5087 10.4429C85.5087 12.868 84.8451 13.9074 83.281 13.9074H81.9538V7.07741H83.281ZM83.5654 19.797C85.2243 19.797 85.8879 20.8363 85.8879 23.2614C85.8879 25.835 85.2243 26.8249 83.5654 26.8249H81.9538V19.797H83.5654Z" />
      <path d="M106.94 20.5888H103.623L105.187 9.75H105.329L106.94 20.5888ZM110.495 0.39594H100.305L94.0953 33.4569H101.727L102.675 26.9239H107.888L108.836 33.4569H116.705L110.495 0.39594Z" />
      <path d="M112.897 0.39594L120.054 22.4201V33.4569H128.017V22.4201L135.127 0.39594H127.116L124.178 11.3833H124.083L121.191 0.39594H112.897Z" />
      <path d="M153.04 33.8528C158.68 33.8528 162.093 29.8934 162.52 23.1129L155.031 21.1827C154.983 24.7957 154.32 26.4784 152.992 26.4784C151.286 26.4784 150.48 23.4594 150.48 16.8769C150.48 10.2944 151.239 7.37437 152.992 7.37437C154.32 7.37437 154.936 8.80965 154.983 12.5216L162.472 10.5914C162.046 3.86041 158.586 0 153.04 0C145.883 0 142.043 5.93909 142.043 16.8769C142.043 27.8147 145.977 33.8528 153.04 33.8528Z" />
      <path d="M171.745 33.4569V0.39594H163.545V33.4569H171.745Z" />
      <path d="M192.478 7.37437V0.39594H172.855V7.37437H178.59V33.4569H186.742V7.37437H192.478Z" />
      <path d="M192.309 0.39594L199.466 22.4201V33.4569H207.429V22.4201L214.539 0.39594H206.528L203.59 11.3833H203.495L200.603 0.39594H192.309Z" />
      <path d="M214.615 0.39594L216.179 22.816H222.436L224 0.39594H214.615ZM219.307 24.4987C216.701 24.4987 214.757 26.5279 214.757 29.151C214.757 31.7741 216.701 33.8033 219.307 33.8033C221.914 33.8033 223.858 31.7741 223.858 29.151C223.858 26.5279 221.914 24.4987 219.307 24.4987Z" />
    </LogoWrapper>
  );
};

export default Logo;

/**
 * Styled Components
 *
 */
const LogoWrapper = styled.svg`
  fill: ${props => (props.fill ? props.fill : 'var(--color-navy)')};
  overflow: visible;
  transition: fill 200ms var(--ease-quart-in-out);
`;
