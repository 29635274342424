import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';

function Seo({ description, image, title, children, themeColor }) {
  const staticData = useStaticQuery(graphql`
    query {
      prismicGlobalLayout {
        _previewable
        data {
          site_title
          site_description
          site_image {
            url
          }
        }
      }
    }
  `);

  // eslint-disable-next-line no-unused-vars
  const { data, isPreview } = useMergePrismicPreviewData(staticData);

  const document = data.prismicGlobalLayout;
  const { site_title, site_description, site_image } = document.data;

  const defaultTitle = site_title;
  const metaDescription = description || site_description;
  const metaImage = image || site_image.url;

  return (
    <>
      <title>{defaultTitle ? `${title} | ${defaultTitle}` : title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="theme-color" content={themeColor ? themeColor : '#F6F5ED'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={``} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      {children}
    </>
  );
}

export default Seo;
